var slideTimer;
var slide = {
	current: 0,
	max: 2,
	duration: 8000,
	init: function(){
		slide.max 		= $(".main ul li").length;
		slide.current 	= 0;
		slideTimer 		= setInterval(function(){
			slide.inc();
		},slide.duration);
	},
	inc: function(){
		if(!$("body").hasClass("scr_sp")){
			$(".main ul li").eq(slide.current).addClass("prev");
			slide.current++;
			if(slide.current >= slide.max){
				slide.current = 0;
			}
			slide.change();
		}
	},
	change: function(){
		//console.log(slide.current, " / ", slide.max);
		$(".main ul li").eq(slide.current).addClass("active zoom");
		setTimeout(function(){
			$(".main ul li.prev").removeClass("active zoom prev");
		},1500);
	}
}