var Block = {
	id: "",
	top: 0,
	show: 0
}
var blocks = [];
var Animate = {
	top: 0,
	show: 0
}
var animations = [];
var isPage = "";
var common = {
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		common.nav();
		if(isPage === "index"){
			
		}else if(isPage === "contact"){
			contact.init();
		}else{
		}
		common.resize();
		if(isPage === "index"){
			common.scrl();
			loadblog.init();
		}
		setTimeout(function(){
			$("body").removeClass("disable");
			common.resize();
			slide.init();
			if(isPage === "index"){
				$(document).off().on("scroll", function(){
					common.scrl();
				});
			}
		},600);
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	nav: ()=>{
		if(isPage === "index"){
			$("h1 a").off().on("click", function(){
				common.scrollToPosition(0);
			});
		}
		$("a.btn_pagetop").off().on("click", function(){
			common.scrollToPosition(0);
		});
		var $body = $("body");
		$("a#btn_menu").off().on("click", function(){
			if(!$body.hasClass("nav")){
				$body.addClass("nav");
			}else{
				$body.removeClass("nav");
			}
			$("#block_nav li a").off().on("click", function(){
				$body.removeClass("nav");
				var idx 	= $("#block_nav li a").index(this);
				var posY	= blocks[idx+1].top;
				common.scrollToPosition(posY);
			});
		});
		$(".btn_index a").off().on("click", function(){
			var idx 	= $(this).attr("data-id");
			var size 	= $(this).attr("data-size");
			var $this 	= $(this);
			$(".table:eq(" + size + ")").find(".block_index:eq(" + idx + ") .index_inner").animate({
				height: "toggle", opacity: "toggle"
			});
			if(!$this.hasClass("active")){
				$this.addClass("active");
			}
		});
		$(".select_classify li label").off().on("click", function(){
			if(!$(this).hasClass("active")){
				$(".select_classify li label").removeClass("active");
				$(this).addClass("active");
			}
		});
		$(".form_course li label").off().on("click", function(){
			if(!$(this).hasClass("active")){
				$(".form_course li label").removeClass("active");
				$(this).addClass("active");
			}
		});
		$(".form_exam .select label").off().on("click", function(){
			if(!$(this).hasClass("active")){
				$(this).addClass("active");
			}
		});
		$("header nav li a").off().on("click", function(){
			var idx = $("header nav li a").index(this);
			var posY = blocks[idx+1].top;
			common.scrollToPosition(posY);
		})
		//share
		$(".sharewith li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".sharewith li a").index(this);
			var url = $(this).attr("data-share");
			shareTxt(idx,url);
		});
		function shareTxt(num,shareUrl){
			var domain = "http://" + document.domain;
			var url 	= domain + shareUrl;
			if(num === 0){
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");

		common.checkBlock();
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	checkBlock: function(){
		blocks = [];
		animations = [];
		$(".block").each(function(i){
			var _b 	= object(Block);
			_b.top 	= Math.floor($(this).offset().top - $("header").height());
			_b.show = Math.floor(_b.top + $(window).height()/3);
			_b.id = $(this).attr("id");
			blocks.push(_b);
		});
		$(".a").each(function(){
			var _a 	= object(Animate);
			_a.top 	= $(this).offset().top;
			_a.show = _a.top + $(window).height()/3;
			animations.push(_a);
		});
		//console.log(blocks)
	},
	scrollToPosition: (posY) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				
			}
		});
	},
	scrl: () =>{
		var topD 	= $(document).scrollTop(),
			btmD 	= topD + $(window).height(),
			len 	= blocks.length,
			alen 	= animations.length,
			$body 	= $("body");
		for(var i = 0; i < len; i++){
			if(i === len - 1){
				if(topD >= blocks[i].top){
					activeBlock(i);
				}
			}else{
				if(topD >= blocks[i].top && topD < blocks[i+1].top){
					activeBlock(i);
				}
			}
		}
		//.a
		for(var j = 0; j < alen; j++){
			if(j === alen-1){
				if(btmD >= animations[j].show){
					ActiveAnimate(j);
				}
			}else{
				if(btmD >= animations[j].show){
					ActiveAnimate(j);
				}
			}
		}
		function activeBlock(i){
			//1回目hashが変更出来るようになったらスタート
			var $blk = $(".block").eq(i);
			if(!$blk.hasClass("active")){
				$("header nav li a").removeClass("active");
				if(i > 0){
					$("header nav li").eq(i-1).find("a").addClass("active");
				}
				$(".block").removeClass("active");
				$blk.addClass("active");
			}
		}
		function ActiveAnimate(j){
			//1回目hashが変更出来るようになったらスタート
			var $blk = $(".a").eq(j);
			if(!$blk.hasClass("animate")){
				$blk.addClass("animate");
				setTimeout(function(){
					$blk.addClass("animated");
				},1500);
			}
		}
		//個別
		if(topD > 10){
			if(!$body.hasClass("scrolled")){
				$body.addClass("scrolled");
			}
		}else{
			if($body.hasClass("scrolled")){
				$body.removeClass("scrolled");
			}
		}
		if(topD >= blocks[1].top){
			if(!$body.hasClass("scr_sp")){
				$body.addClass("scr_sp");
			}
		}else{
			if($body.hasClass("scr_sp")){
				$body.removeClass("scr_sp");
			}
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
	if(isIE11) {
		$('body').on("mousewheel", function () {
			event.preventDefault();
			var wd 	= event.wheelDelta;
			var csp = window.pageYOffset;
			window.scrollTo(0, csp - wd);
		});
	}
});