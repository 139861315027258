var total = [0,0,0];
var contact = {
	init: function(){
		if($("body").hasClass("contact")){
			contact.cloneContact();
		}else if($("body").hasClass("buy")){
			contact.cloneBuy();
		}
		document.addEventListener( 'wpcf7mailsent', function( event ) {
			$("#contact").addClass("thanks");
		}, false );
	},
	cloneContact: function(){
		var CloneHTML 	= $("#temp .temp_inr").clone(true);
		var CloneHTML02 = $("#temp02 .temp_inr").clone(true);

		$("div#load_course").append(CloneHTML);
		$("div#load_exam").append(CloneHTML02);
		
		//input
		$(".wpcf7-list-item").each(function(i){
			//console.log(i);
			$(this).find("label").prepend('<span class="radio-icon"></span>');
		})
		setTimeout(function(){
			common.nav();
			$("#temp").remove();
			$("#temp02").remove();
			$("body").removeClass("disable");
		}, 600);
	},
	cloneBuy: function(){
		$("#temp ul li").each(function(i){
			var cloneHTML = $(this).find(".img_text").clone(true);
			//console.log(cloneHTML)
			$("ul.select_text li").eq(i).find(".book").append(cloneHTML);
		});
		setTimeout(function(){
			$("#temp").remove();
			contact.calcTotal();
			$("body").removeClass("disable");
		},600);
	},
	calcTotal: function(){
		$("ul.select_text li select").on("change", function(){
			var idx = $("ul.select_text li select").index(this);
			var selected = $("ul.select_text li").eq(idx).find("option:selected");
			var sidx = $("ul.select_text li").eq(idx).find("option").index(selected);
			var price = parseInt($("ul.select_text li").eq(idx).find("p.price").attr("data-price").replace(",", ""));

			total[idx] = sidx*price;
			contact.addTotal();
		});
	},
	addTotal: function(){
		var totalprice = 0;
		$("ul.select_text li").each(function(i){
			totalprice += total[i];
		});
		$("#total dd span").html(totalprice.toLocaleString());
		//console.log(totalprice)
	}
}