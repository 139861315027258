var loadblog = {
	postMax: 10,
	init: function(){
		var url = "/api/blog.json";
		var params = {}
		loadblog.getJson(url, params).done(function(json){
			//console.log(json)
			var p = json.posts;
			var len = p.length;
			if(len <= loadblog.postMax){
				len = p.length;
			}else{
				len = loadblog.postMax;
			}
			var HTML = "";
			for(var i = 0; i < len; i++){
				var post = p[i];
				HTML 	+= '<li>'
						+ '<a href="' + post.permalink + '" title="' + post.title + '" target="_blank">'
						+ '<div class="col2">'
						+ '<figure><img src="' + post.eye_catch + '" alt=""></figure>'
						+ '<div class="col_r">'
						+ '<h4>' + post.title + '</h4>'
						+ '<p>' + post.content + ' <span>続きを読む</span></p>'
						+ '</div>'
						+ '</div>'
						+ '</a>'
						+ '</li>'
			}
			$(".block_blog ul").html(HTML);
		});
	},
	getJson: function(url,params){
		var defer 	= $.Deferred();
		var url 	= url;
		$.ajax({
			type: "GET",
			dataType: 'json',
			url: url,
			data: params,
			success	: defer.resolve,
			error: defer.reject
		});
		return defer.promise();
	}
}